import { useState } from 'react';

const useModal = (initialState = false) => {
  const [open, setOpen] = useState(initialState);

  const setModalOpen = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return {
    modalOpen: open,
    setModalOpen,
    closeModal,
  };
};

export default useModal;
