import { Icon, Typography } from 'fgirot-k2-ui-components';
import React, { KeyboardEvent } from 'react';
import './module-card.scss';

interface ModuleCardProps {
  title: string;
  className?: string;
  subTitle?: string;
  iconType: 'Users' | 'Stats' | 'Settings';
  children?: JSX.Element | JSX.Element[];
  dataCy?: string;
  onClick?: () => void;
}

const ModuleCard: React.FunctionComponent<ModuleCardProps> = ({
  title,
  className,
  subTitle,
  iconType,
  children,
  dataCy,
  onClick,
  ...props
}) => {
  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>, onClick) => {
    if (e.code === 'Space' || e.code === 'Enter') {
      e.preventDefault();
      onClick();
    }
  };
  return (
    <div
      {...props}
      className={[className, 'module-card'].join(' ')}
      data-cy={dataCy}
      onClick={onClick}
      tabIndex={0}
      onKeyDown={(event) => handleKeyPress(event, onClick)}
    >
      <div className="module-card--icon-container">
        <Icon type={iconType} className="module-card--icon-container__icon" />
      </div>
      <Typography className="module-card__typography" bold variant="h2" component="h2">
        {title}
      </Typography>
      {subTitle && (
        <Typography bold variant="body2">
          {subTitle}
        </Typography>
      )}
      <div className="module-card--children-container">{children}</div>
    </div>
  );
};

export default ModuleCard;
