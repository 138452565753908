import { Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import './module-card-box.scss';

interface ModuleCardBoxProps {
  label?: string | JSX.Element | JSX.Element[];
  value?: string;
  index?: number;
  children?: JSX.Element | JSX.Element[];
  onClick?: () => void;
  dataCy?: string;
  className?: string;
}

const ModuleCardBox: FC<ModuleCardBoxProps> = ({
  label,
  value,
  index,
  children,
  onClick,
  dataCy,
  className,
  ...props
}) => {
  return (
    <div
      {...props}
      className={[className, 'module-card-box'].join(' ')}
      key={`module-card-box-${label}--${index}`}
      onClick={onClick}
      data-cy={dataCy}
    >
      {typeof label === 'string' ? (
        <Typography className="module-card-box--label" variant="caption">
          {label}
        </Typography>
      ) : (
        <>{label}</>
      )}
      {children}
      <Typography bold variant="body2">
        {value}
      </Typography>
    </div>
  );
};

export default ModuleCardBox;
