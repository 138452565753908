import { Button, Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from 'src/components/Common/FilterButton';
import { PaymentsFilter } from 'src/types/PaymentsFilter';
import './payments-filter-details.scss';

interface PaymentsFilterDetailsProps {
  filters: PaymentsFilter;
  applyFilters: (filters: PaymentsFilter) => void;
}

export const PaymentsFilterDetails: FC<PaymentsFilterDetailsProps> = ({ filters, applyFilters }) => {
  const { t } = useTranslation();
  const filtersApplied =
    filters?.paymentCreatedDate?.from?.date?.length > 0 ||
    filters?.paymentCreatedDate?.to?.date?.length > 0 ||
    filters?.paymentDueDate?.fromDate?.length > 0 ||
    filters?.paymentDueDate?.toDate?.length > 0 ||
    filters?.banks?.length > 0 ||
    filters?.paymentServiceProviders?.length > 0 ||
    filters.paymentStatuses?.length > 0;

  const removeCustomerFilter = () => {
    applyFilters({
      ...filters,
      customerId: '',
      employerId: '',
    });
  };

  const removeEmployerFilter = () => {
    applyFilters({
      ...filters,
      employerId: '',
    });
  };

  const removeFromPaymentCreatedDate = () => {
    applyFilters({
      ...filters,
      paymentCreatedDate: {
        from: { date: '', time: '' },
        to: filters.paymentCreatedDate.to,
      },
    });
  };

  const removeToPaymentCreatedDate = () => {
    applyFilters({
      ...filters,
      paymentCreatedDate: {
        from: filters.paymentCreatedDate.from,
        to: { date: '', time: '' },
      },
    });
  };

  const removeFromPaymentDueDate = () => {
    applyFilters({
      ...filters,
      paymentDueDate: {
        fromDate: '',
        toDate: filters.paymentDueDate.toDate,
      },
    });
  };

  const removeToPaymentDueDate = () => {
    applyFilters({
      ...filters,
      paymentDueDate: {
        fromDate: filters.paymentDueDate.fromDate,
        toDate: '',
      },
    });
  };

  const removeBanksFilter = () => {
    applyFilters({
      ...filters,
      banks: null,
    });
  };

  const removePaymentStatusesFilter = () => {
    applyFilters({
      ...filters,
      paymentStatuses: null,
    });
  };

  const removePaymentServiceProvidersFilter = () => {
    applyFilters({
      ...filters,
      paymentServiceProviders: null,
    });
  };

  const removeAllFilters = () => {
    applyFilters({
      paymentCreatedDate: {
        from: {
          date: '',
          time: '',
        },
        to: {
          date: '',
          time: '',
        },
      },
      paymentDueDate: {
        fromDate: '',
        toDate: '',
      },
      paymentStatuses: [],
      banks: [],
      paymentServiceProviders: [],
      customerId: '',
      employerId: '',
    });
  };

  return (
    <div className="payments-filter-details">
      <Typography variant="caption" bold>
        Filter:
      </Typography>
      {!filtersApplied && (
        <Typography variant="caption" italic>
          Inga filter
        </Typography>
      )}
      {filtersApplied && (
        <>
          {filters?.customerId?.length > 0 && (
            <FilterButton label={t('economy:search-payments-filters:remove-customer')} onClick={removeCustomerFilter} />
          )}
          {filters?.employerId?.length > 0 && (
            <FilterButton label={t('economy:search-payments-filters:remove-employer')} onClick={removeEmployerFilter} />
          )}
          {filters?.paymentDueDate?.fromDate?.length > 0 && (
            <FilterButton
              label={
                filters?.paymentDueDate?.fromDate + ' ' + t('economy:search-payments-filters:from-payment-due-date')
              }
              onClick={removeFromPaymentDueDate}
            />
          )}
          {filters?.paymentDueDate?.toDate?.length > 0 && (
            <FilterButton
              label={filters?.paymentDueDate?.toDate + ' ' + t('economy:search-payments-filters:to-payment-due-date')}
              onClick={removeToPaymentDueDate}
            />
          )}
          {filters?.paymentCreatedDate?.from?.date?.length > 0 && (
            <FilterButton
              label={
                `${filters?.paymentCreatedDate?.from?.date} ${
                  filters?.paymentCreatedDate?.from?.time === undefined ? '' : filters?.paymentCreatedDate?.from?.time
                } ` + t('economy:search-payments-filters:from-payment-created-date')
              }
              onClick={removeFromPaymentCreatedDate}
            />
          )}
          {filters?.paymentCreatedDate?.to?.date?.length > 0 && (
            <FilterButton
              label={
                `${filters?.paymentCreatedDate?.to?.date} ${
                  filters?.paymentCreatedDate?.to?.time === undefined ? '' : filters?.paymentCreatedDate?.to?.time
                } ` + t('economy:search-payments-filters:to-payment-created-date')
              }
              onClick={removeToPaymentCreatedDate}
            />
          )}
          {filters?.banks?.length > 0 && (
            <FilterButton
              label={filters?.banks.length + ' ' + t('economy:search-payments-filters:bank')}
              onClick={removeBanksFilter}
            />
          )}
          {filters?.paymentStatuses?.length > 0 && (
            <FilterButton
              label={filters?.paymentStatuses.length + ' ' + t('economy:search-payments-filters:payment-status')}
              onClick={removePaymentStatusesFilter}
            />
          )}
          {filters?.paymentServiceProviders?.length > 0 && (
            <FilterButton
              label={
                filters?.paymentServiceProviders.length +
                ' ' +
                t('economy:search-payments-filters:payment-service-provider')
              }
              onClick={removePaymentServiceProvidersFilter}
            />
          )}
          <Button
            type="warning"
            size="small"
            label={t('economy:search-payments-filters:remove-all')}
            onClick={removeAllFilters}
          />
        </>
      )}
    </div>
  );
};

export default PaymentsFilterDetails;
