import React, { useEffect } from 'react';
import './App.scss';
import NavigationBar from './components/PageBase/NavigationBar/NavigationBar';
import Customers from './pages/Customers';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoute, useKeycloak } from 'fgirot-k2-react-keycloak-provider';
import InsuranceManager from './pages/InsuranceManager';
import CustomerDetails from './components/Customers/CustomerDetails';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Economy from './pages/Economy';
import Permission from './pages/Permission';
import ExternalPermission from './pages/Permission/ExternalPermission';
import InternalPermission from './pages/Permission/InternalPermission';
import { ToastsContainer } from 'fgirot-k2-ui-components';
import Monitoring from './pages/Monitoring';
import Documents from './pages/Documents';
import { loggedInUserIsInternal } from 'src/service/user/getUserFromToken';
import EnvironmentInfo from './components/PageBase/EnvironmentInfo';

const App = () => {
  const { t } = useTranslation();
  const { keycloak, initialized } = useKeycloak();
  useEffect(() => {
    document.title = t('common:page-title');
  }, [i18next.language]);

  return (
    initialized && (
      <Router>
        {!keycloak.authenticated ? (
          <>{keycloak.login()}</>
        ) :
        !loggedInUserIsInternal() ? (
          <>{keycloak.logout()}</>
        ) : (
          <PrivateRoute>
            <NavigationBar />
            <div className="page-container">
              <Routes>
                <Route path="/customers">
                  <Route index element={<Customers />} />
                  <Route path={':customerId/customer'} element={<CustomerDetails />} />
                </Route>
                <Route path="/economy" element={<Economy />} />
                <Route path="/permission" element={<Permission />} />
                <Route path="/permission/external" element={<ExternalPermission />} />
                <Route path="/permission/internal" element={<InternalPermission />} />
                <Route path="/products" element={<InsuranceManager />} />
                <Route path="/documents" element={<Documents />} />
                <Route path="/monitoring" element={<Monitoring />} />
                <Route path="" element={<Navigate to="/customers" />} />
                <Route path="*" element={<Navigate to="/customers" />} />
              </Routes>
            </div>
            <ToastsContainer />
            <EnvironmentInfo />
          </PrivateRoute>
        )}
      </Router>
    )
  );
};

export default App;
