import { useTranslation } from 'react-i18next';

export const getDaysShort = () => {
  const { t } = useTranslation();

  return [
    t('common:days.short.monday'),
    t('common:days.short.tuesday'),
    t('common:days.short.wednesday'),
    t('common:days.short.thursday'),
    t('common:days.short.friday'),
    t('common:days.short.saturday'),
    t('common:days.short.sunday'),
  ];
};
