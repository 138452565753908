import { Payment } from 'src/types/Payment';
import { PaymentsFilter } from 'src/types/PaymentsFilter';

export const defaultPaymentsFilter = (): PaymentsFilter => {
  return {
    paymentCreatedDate: {
      from: { date: '', time: '' },
      to: { date: '', time: '' },
    },
    paymentDueDate: {
      fromDate: '',
      toDate: '',
    },
    paymentStatuses: ['PAYMENT_REQUEST_CREATED', 'PAYMENT_FAILED'],
    banks: [],
    paymentServiceProviders: [],
    customerId: '',
    employerId: '',
  };
};

export const groupPaymentByCustomer = (payments: Payment[]): Map<string, Payment[]> => {
  return payments.reduce((map, payment) => {
    const grouped = map.get(payment.customerId);
    if (grouped) {
      grouped.push(payment);
    } else {
      map.set(payment.customerId, [payment]);
    }
    return map;
  }, new Map<string, Payment[]>());
};

export const groupPaymentByEmployer = (payments: Payment[]): Map<string, Payment[]> => {
  return payments.reduce((map, payment) => {
    const grouped = map.get(payment.employer.id);
    if (grouped) {
      grouped.push(payment);
    } else {
      map.set(payment.employer.id, [payment]);
    }
    return map;
  }, new Map<string, Payment[]>());
};
