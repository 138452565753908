import { Modal } from 'fgirot-k2-ui-components';

interface DrawerModalProps {
  open: boolean;
  onClose: () => void;
  overlayBackground?: boolean;
  overlayOnClose?: boolean;
  noAnimation?: boolean;
  children?: JSX.Element | JSX.Element[];
}

const DrawerModal: React.FunctionComponent<DrawerModalProps> = ({
  open,
  onClose,
  overlayBackground,
  overlayOnClose,
  noAnimation,
  children,
  ...props
}) => {
  return (
    <Modal
      onClose={overlayOnClose && onClose}
      open={open}
      type="drawer"
      overlayBackground={overlayBackground}
      noAnimation={noAnimation}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default DrawerModal;
