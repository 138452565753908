import { gql } from '@apollo/client';

export const GET_CUSTOMERS = gql`
  query customers($pageNumber: Int, $pageSize: Int) {
    customers(pageNumber: $pageNumber, pageSize: $pageSize) {
      customers {
        id
        name
      }
      paging {
        totalNumberOfElements
      }
    }
  }
`;

export const GET_ALL_CUSTOMERS = gql`
  query customers {
    customers(pageNumber: 0, pageSize: 50000) {
      customers {
        id
        name
      }
    }
  }
`;
