import { InlineEdit, Typography } from 'fgirot-k2-ui-components';

interface ListPropertyProps {
  label: string | JSX.Element;
  value: string | JSX.Element;
}

const ListProperty = ({ label, value, ...props }: ListPropertyProps) => (
  <InlineEdit label={label} bold {...props}>
    <Typography variant="body2">{value}</Typography>
  </InlineEdit>
);

export default ListProperty;
