import React from 'react';
import './drawer-breadcrumbs.scss';
import { Typography } from 'fgirot-k2-ui-components';

interface DrawerBreadcrumbsProps {
  breadcrumbs: string[];
}

const DrawerBreadcrumbs = ({ breadcrumbs }: DrawerBreadcrumbsProps) => {
  return (
    <div className="drawer-breadcrumbs">
      {breadcrumbs.map((breadcrumb, index) => (
        <span key={index}>
          <Typography variant="caption2" className="drawer-breadcrumbs__breadcrumb">
            {breadcrumb}
          </Typography>
          <Typography variant="caption2" className="drawer-breadcrumbs__breadcrumb">
            /
          </Typography>
        </span>
      ))}
    </div>
  );
};

export default DrawerBreadcrumbs;
