import { SortDirection, TableBody, TableHead } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import { Payment } from 'src/types/Payment';
import PaymentsTableHeads from './PaymentsTableHeads';
import PaymentsTableRow from './PaymentsTableRow';
import { PaymentsTableSortField } from './PaymentsTableSortField';
import PaymentDrawer from '../PaymentDrawer';
import './payments-table.scss';
import { Sort } from 'src/types/common/Sort';

interface PaymentsTableProps {
  payments: Payment[];
  sorting: Sort;
  checked: { id: string; checked: boolean }[];
  applySorting(property: string, direction: SortDirection);
  onChange: (id: string) => void;
}

const PaymentsTable: FC<PaymentsTableProps> = ({
  payments,
  sorting,

  checked,
  applySorting,
  onChange,
}) => {
  const [selectedPaymentId, setSelectedPaymentId] = useState<string>(undefined);

  const handleOpenPaymentDrawer = (id: string) => {
    setSelectedPaymentId(id);
  };

  const handleClosePaymentDrawer = () => {
    setSelectedPaymentId(undefined);
  };

  const handleSort = (property: PaymentsTableSortField) => {
    if (property == sorting.property) {
      applySorting(property, sorting.direction == 'UP' ? 'DOWN' : 'UP');
    } else {
      applySorting(property, sorting.direction);
    }
  };

  return (
    <>
      <div className="payments-table">
        <TableBase compressed>
          <TableHead filled>
            <PaymentsTableHeads sorting={sorting} onApplySorting={handleSort} />
          </TableHead>
          <TableBody>
            {payments.map((payment) => (
              <PaymentsTableRow
                key={`PaymentsTableRow-${payment.id}`}
                payment={payment}
                onChange={() => onChange(payment.id)}
                checked={checked.find((box) => box.id === payment.id)?.checked ?? false}
                onRowClick={handleOpenPaymentDrawer}
              />
            ))}
          </TableBody>
        </TableBase>
      </div>
      {selectedPaymentId !== undefined && (
        <PaymentDrawer
          open={!!selectedPaymentId}
          onClose={handleClosePaymentDrawer}
          payment={payments.find((payment) => payment.id === selectedPaymentId)}
        />
      )}
    </>
  );
};

export default PaymentsTable;
