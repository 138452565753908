import { DocumentNode } from '@apollo/client';

export const extractDataField = (query: DocumentNode) => {
  const operationDefinition = query.definitions[0];
  if (operationDefinition.kind === 'OperationDefinition') {
    const selectionSet = operationDefinition.selectionSet.selections[0];
    if (selectionSet?.kind === 'Field') {
      return selectionSet.name.value;
    }
  } else {
    throw new Error('invalid query provided');
  }
};
