import React, { ReactNode } from 'react';
import './content-slot.scss';

interface ContentSlotProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: ReactNode | ReactNode[];
}

const ContentSlot = (props: ContentSlotProps) => {
  const { className, children } = props;
  return (
    <div {...props} className={[`content-slot`, className].join(' ')}>
      {children}
    </div>
  );
};

export default ContentSlot;
