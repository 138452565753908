import React from 'react';
import './environment-info.scss';

type GuessedEnv = 'DEV' | 'TEST' | 'ACC' | 'PROD';

const EnvironmentInfo = () => {
  const guessEnv = (): GuessedEnv => {
    if (window.location.href.includes('dev.k2.fgirot.se')) {
      return 'DEV';
    }
    if (window.location.href.includes('test.k2.fgirot.se')) {
      return 'TEST';
    }
    if (window.location.href.includes('minasidor.fgirot.net')) {
      return 'ACC';
    }
    return 'PROD';
  };

  const env = guessEnv();

  switch (env) {
    case 'DEV':
    case 'ACC':
    case 'TEST':
      return <span id="environment-info">{env}</span>;
    case 'PROD':
      return null;
  }
};
export default EnvironmentInfo;
