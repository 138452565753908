import { Button, Icon } from 'fgirot-k2-ui-components';
import './filter-button.scss';

const FilterButton = ({ onClick, label }: { onClick: () => void; label: string }) => (
  <Button
    className="filter-button"
    type="primary"
    size="small"
    label={label}
    icon={<Icon type="Cross" size="small" />}
    onClick={onClick}
  />
);

export default FilterButton;
