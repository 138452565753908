import { gql } from '@apollo/client';

export const GET_USER = gql`
  query ($userId: UUID!) {
    user(userId: $userId) {
        id
        firstName
        lastName
        email
        phone
        identifier
        identifierType
        orderRef
        userType
        admin
        validTo
        accountNonLocked
        customers {
          name
          id
        }
      }
    
  }
`;

export const GET_EXTERNAL_USER = gql`
  query ($userId: UUID!, $customerId: UUID!) {
    internalExternalUser(userId: $userId, customerId: $customerId) {
      user {
        id
        firstName
        lastName
        email
        phone
        identifier
        identifierType
        orderRef
        userType
        admin
        validTo
        accountNonLocked
      }
      customers {
          name
          id
        }
    }
  }
`;