import { TableRow, Th } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableHeadProps } from 'src/types/TableHeadProps';
import { PaymentsTableSortField } from './PaymentsTableSortField';
import { Sort } from 'src/types/common/Sort';

interface PaymentsTableHeadsProps {
  sorting: Sort;
  onApplySorting(property: PaymentsTableSortField);
}

const PaymentsTableHeads: FC<PaymentsTableHeadsProps> = ({ sorting, onApplySorting }) => {
  const { t } = useTranslation();

  const tableHeads: TableHeadProps[] = [
    {
      title: t(''),
      fieldName: 'checkbox',
      includeSortButtons: false,
    },
    {
      title: t('economy:payments-tab.table-head.employer'),
      fieldName: 'employer',
    },
    {
      title: t('economy:payments-tab.table-head.insurance-provider'),
      fieldName: 'insuranceProvider',
    },
    {
      title: t('economy:payments-tab.table-head.invoice-number'),
      fieldName: 'invoiceNumber',
    },
    {
      title: t('economy:payments-tab.table-head.amount'),
      sortDirection: sorting.direction,
      includeSortButtons: true,
      fieldName: 'amount',
      handleSortDirectionChange: () => {
        onApplySorting('amount');
      },
    },
    {
      title: t('economy:payments-tab.table-head.payment-status'),
      fieldName: 'paymentStatus',
    },
    {
      title: t('economy:payments-tab.table-head.payment-error-code'),
      fieldName: 'paymentErrorCode',
    },
    {
      title: t('economy:payments-tab.table-head.payment-due-date'),
      fieldName: 'dueDate',
      sortDirection: sorting.direction,
      includeSortButtons: true,
      handleSortDirectionChange: () => {
        onApplySorting('dueDate');
      },
    },
    {
      title: t('economy:payments-tab.table-head.payment-created-date'),
      sortDirection: sorting.direction,
      includeSortButtons: true,
      fieldName: 'createdDate',
      handleSortDirectionChange: () => {
        onApplySorting('createdDate');
      },
    },
    {
      title: t('economy:payments-tab.table-head.time-stamp'),
      fieldName: 'timeStamp',
    },
    {
      title: t('economy:payments-tab.table-head.payment-service-provider'),
      fieldName: 'paymentServiceProvider',
      sortDirection: sorting.direction,
      includeSortButtons: true,
      handleSortDirectionChange: () => {
        onApplySorting('paymentServiceProvider');
      },
    },
    {
      title: t('economy:payments-tab.table-head.bank'),
      fieldName: 'bank',
      sortDirection: sorting.direction,
      includeSortButtons: true,
      handleSortDirectionChange: () => {
        onApplySorting('bank');
      },
    },
    {
      title: t('economy:payments-tab.table-head.account-from'),
      fieldName: 'accountFrom',
    },
    {
      title: t('economy:payments-tab.table-head.account-to'),
      fieldName: 'accountTo',
    },
    {
      title: t('economy:payments-tab.table-head.description'),
      fieldName: 'description',
    },
  ];

  return (
    <TableRow>
      {tableHeads.map((head) => {
        return (
          <Th
            compressed
            includeSortButtons={head.includeSortButtons}
            sortDirection={head.fieldName === sorting.property ? sorting.direction : undefined}
            key={`payments-table-heads-${head.fieldName}`}
            handleSortDirectionChange={head.handleSortDirectionChange}
          >
            {head.title}
          </Th>
        );
      })}
    </TableRow>
  );
};

export default PaymentsTableHeads;
