import { gql } from '@apollo/client';
import { UserFilter } from 'src/types/user/Users';


export const GET_EXTERNAL_ADMIN_USERS = gql`
  query ($userFilter: String, $customerId: UUID $pageSize: Int, $pageNumber: Int) {
    internalExternalUsers(userFilter: $userFilter, customerId: $customerId, pageSize: $pageSize, pageNumber: $pageNumber) {
      paging {
        totalNumberOfElements
      }
      externalUsers {
        user {
          firstName
          lastName
          email
          id
        }
        externalGroups{
          customerName
          admin
        }
      }
    }
  }
`;

export const GET_TOTAL_NUMBER_OF_EXTERNAL_ADMIN_USERS = gql`
  query {
    users(userFilter: "${UserFilter.ADMIN_USERS}", pageSize: 1, pageNumber: 0) {
      paging {
        totalNumberOfElements
      }
    }
  }
`;
