import { useTranslation } from 'react-i18next';
import PageBase from '../../../components/PageBase';
import React, { FC, useState } from 'react';
import './external-permission.scss';
import AdminUsersTab from 'src/components/Permission/ExternalPermission/AdminUsersTab';
import { Tab, Tabs } from 'fgirot-k2-ui-components';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_ALL_CUSTOMERS } from 'src/graphql/schema/query/customer/GetCustomers';
import { GetCustomerPage } from 'src/types/Customer';
import { Option, Select} from 'fgirot-k2-ui-components';
import { createOptionsFromList } from 'src/util/OptionUtil';
import { Customer } from 'src/types/Customer';

const ExternalPermission = () => {
  const { t } = useTranslation();
  
  const [customer, setCustomer] = useState<Customer>({
    id: '',
    name: '',
  });

  const handleCustomerChange = (option: Option<string>) => {
    setCustomer((prev) => {
      return { ...prev, id: option.value };
    });
  };

  return (
    <PageBase title={t('permission:external.title')} header={true}>
      <QueryWrapper query={GET_ALL_CUSTOMERS}>
        {({ customers }: GetCustomerPage)=> {
          const customerOptions = createOptionsFromList(customers, 'id', 'name');
          return (
            <div className="external-permission">
            <div>
              <Select
                placeholder={t('documents:filters.customer-placeholder')}
                options={customerOptions}
                selected={customerOptions.find((option) => option.value === customer.id)}
                onChange={handleCustomerChange}
              />
            </div>
              <Tabs className="internal-permission" tabIndex={0}>
                <Tab title={t('permission:external.tabs.admin-users-label')}>
                  <AdminUsersTab customers={customers} selectedCustomer={customer} />
                </Tab>
              </Tabs>
            </div>
          )
        }}
      </QueryWrapper>
    </PageBase>
  );
};

export default ExternalPermission;
