import { Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './max-character-limit.scss';

interface MaxCharacterLimitProps {
  currentCharacters: number;
  characterLimit: number;
}

const MaxCharacterLimit: React.FunctionComponent<MaxCharacterLimitProps> = ({ currentCharacters, characterLimit }) => {
  const { t } = useTranslation();

  return (
    <div className="max-character-limit__footer">
      <Typography className="max-character-limit__text" variant="body2">
        {`${currentCharacters} ${t('common:of')} ${characterLimit} ${t('common:characters')}`}
      </Typography>
    </div>
  );
};

export default MaxCharacterLimit;
