import { gql } from '@apollo/client';

export const SEARCH_INSURANCE_TYPE_MAPPINGS = gql`
  query SearchInsuranceTypeMappings($request: InsuranceTypeMappingsRequest) {
    searchInsuranceTypeMappings(request: $request) {
      paging {
        totalNumberOfElements
        hasNext
      }
      searchInsuranceTypeMappings {
        id
        name
        benefitType
        taxRule
        insuranceCategory
        premiumSource
        identifier
        insuranceProvider {
          id
          legalName
        }
        invoiceRowMappings {
          id
          invoiceRowName
        }
      }
    }
  }
`;
