import { Icon, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ContentSlot from '../ContentSlot';
import './error-page.scss';

interface ErrorPageProps {
  className?: string;
  errorMessage?: string;
}

const ErrorPage = ({ className, errorMessage }: ErrorPageProps) => {
  const { t } = useTranslation();
  return (
    <ContentSlot className={['error-page', className].join(' ')}>
      <Icon size="large" type="Alert" />
      <Typography className="error-page__text" variant="h3">
        {t('common:error')}
      </Typography>
      {errorMessage && <Typography className={['error-page__details', 'italic'].join(' ')}>{errorMessage}</Typography>}
    </ContentSlot>
  );
};

export default ErrorPage;
