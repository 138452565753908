import { Button, Icon, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './page-base.scss';
import PageHeader from './PageHeader';

interface BreadCrumbs {
  name: string;
  steps: number;
}

interface PageBaseProps {
  title: string;
  handleBackButton?: () => void;
  children: JSX.Element | JSX.Element[];
  subtitle?: string;
  header?: boolean;
  breadCrumbs?: BreadCrumbs[];
}

const PageBase = ({ title, subtitle, header, handleBackButton, breadCrumbs, children }: PageBaseProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={['page-base'].join(' ')}>
      <header className="page-base__header">
        {header && <PageHeader />}
        {handleBackButton && (
          <Button
            className="page-base__back-button"
            size="small"
            type="link"
            icon={<Icon type="ArrowLeft" />}
            label={t('common:back')}
            onClick={handleBackButton}
          />
        )}
        {breadCrumbs && (
          <ul className="page-base__breadcrumb-list">
            {breadCrumbs.map((crumb, index) => (
              <li
                className="page-base__breadcrumb-list__item"
                onClick={() => navigate(-crumb.steps)}
                key={`PageBase-BreadCrumbs-${crumb.name}-${index}`}
              >
                <Typography variant="caption2" className="page-base__breadcrumb-list__item__typography">
                  {crumb.name}
                </Typography>
              </li>
            ))}
          </ul>
        )}
        <Typography component="h1" variant="h1">
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="caption2" className="page-base__subtitle">
            {subtitle}
          </Typography>
        )}
      </header>
      <div className="page-base__children">{children}</div>
    </div>
  );
};

export default PageBase;
