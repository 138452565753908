import { Loader, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './scroll-load-more.scss';

const ScrollLoadMore = () => {
  const { t } = useTranslation();
  return (
    <div className={['scroll-load-more'].join(' ')}>
      <Loader type="blue" />
      <Typography className="scroll-load-more__text" variant="caption">
        {t('common:loading-more')}
      </Typography>
    </div>
  );
};

export default ScrollLoadMore;
