import { Button, Icon, Pagination, SortDirection } from 'fgirot-k2-ui-components';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateResendPayments } from 'src/service/CreateResendPayments';
import { Paging } from 'src/types/PageableResponse';
import { Payment } from 'src/types/Payment';
import { PaymentsFilter } from 'src/types/PaymentsFilter';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import PaymentsCalculatedAmount from '../PaymentsCalculatedAmount';
import PaymentsFilterDetails from '../PaymentsFilterDetails';
import PaymentsTable from '../PaymentsTable';
import SearchPaymentsFiltersModal from '../SearchPaymentsFiltersModal';
import { groupPaymentByCustomer, groupPaymentByEmployer } from '../utils/paymentUtils';
import { GetCustomerPage } from 'src/types/Customer';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_ALL_CUSTOMERS } from 'src/graphql/schema/query/customer/GetCustomers';
import { Sort } from 'src/types/common/Sort';

interface PaymentsProps {
  payments: Payment[];
  paging: Paging;
  pageNumber: number;
  pageSize: number;
  paymentsFilter: PaymentsFilter;
  sorting: Sort;
  onApplySorting: (property: string, sortDirection: SortDirection) => void;
  onApplyFilters: (filters: PaymentsFilter) => void;
  onPageChange: (nextPageNumber: number) => void;
}

const Payments: FC<PaymentsProps> = ({
  payments,
  paging,
  pageNumber,
  pageSize,
  paymentsFilter,
  sorting,
  onApplySorting,
  onApplyFilters,
  onPageChange,
}) => {
  const { t } = useTranslation();
  const createResendPayments = useCreateResendPayments();

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const handleCloseModal = () => {
    setFiltersModalOpen(false);
  };

  const handleModalOpen = () => {
    setFiltersModalOpen(true);
  };

  const totalPages = getTotalPages(paging?.totalNumberOfElements, pageSize);
  const showPagination = totalPages > 1;

  const initialChecked = payments.map((payment) => {
    return { id: payment.id, checked: false };
  });

  const [checked, setChecked] = useState<{ id: string; checked: boolean }[]>(initialChecked);
  const [allChecked, setAllChecked] = useState<boolean>(false);

  useEffect(() => {
    setAllChecked(checked.every((box) => box.checked));
  }, [checked]);

  useEffect(() => setChecked(initialChecked), [payments]);

  const toggleChecked = (id: string) => {
    setChecked((prev) => {
      return [...prev.filter((box) => box.id !== id), { id: id, checked: !prev.find((box) => box.id === id).checked }];
    });
  };

  const toggleAll = () => {
    setAllChecked((prevAllChecked) => {
      setChecked((prevChecked) => {
        if (prevAllChecked) {
          return prevChecked.map((box) => ({
            ...box,
            checked: false,
          }));
        } else {
          return prevChecked.map((box) => ({
            ...box,
            checked: true,
          }));
        }
      });

      return !prevAllChecked;
    });
  };

  const selectedPaymentIds = checked.filter((box) => box.checked).map((box) => box.id);
  const selectedPayments = payments.filter((payment) => selectedPaymentIds.includes(payment.id));
  const handleResendPayments = () => {
    if (checked.length > 0) {
      const groupedByCustomer = groupPaymentByCustomer(selectedPayments);
      groupedByCustomer.forEach((customerGroup) => {
        const groupedByEmployer = groupPaymentByEmployer(customerGroup);
        groupedByEmployer.forEach((employerGroup) => {
          const paymentIdsToSend = employerGroup.map((payment) => payment.id);
          createResendPayments(
            { payments: paymentIdsToSend },
            employerGroup[0].employer.id,
            employerGroup[0].customerId,
          ).then(() => {
            setChecked(checked.map((item) => ({ ...item, checked: false })));
          });
        });
      });
    }
  };

  return (
    <>
      <div className="payments-tab">
        <div className="payments-tab__buttons-container">
          <Button type="primary" label={t('economy:payments-tab.buttons.mark-button')} onClick={toggleAll} />
          <Button
            type="primary"
            data-testid={`payment-resend-button`}
            label={t('economy:payments-tab.buttons.resend-button')}
            onClick={handleResendPayments}
          />
        </div>
        <PaymentsFilterDetails filters={paymentsFilter} applyFilters={onApplyFilters} />
        <PaymentsTable
          payments={payments}
          sorting={sorting}
          onChange={toggleChecked}
          checked={checked}
          applySorting={onApplySorting}
        />
        {showPagination && (
          <div data-testid="payments-tab-pagination">
            <Pagination
              currentPage={pageNumber}
              onPageChange={(nextPageNumber) => onPageChange(nextPageNumber)}
              totalPages={totalPages}
            />
          </div>
        )}
        {checked && <PaymentsCalculatedAmount payments={selectedPayments} />}
      </div>
      <QueryWrapper query={GET_ALL_CUSTOMERS}>
        {({ customers }: GetCustomerPage) => {
          return (
            filtersModalOpen && (
              <SearchPaymentsFiltersModal
                open={filtersModalOpen}
                customers={customers}
                onClose={handleCloseModal}
                filters={paymentsFilter}
                onApplyFilters={onApplyFilters}
              />
            )
          );
        }}
      </QueryWrapper>
      <div className="payments-tab__filter-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'Sliders'} stroke="white" />}
          label={t('common:filter')}
          onClick={handleModalOpen}
        />
      </div>
    </>
  );
};

export default Payments;
