import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const useTabParams = (tabKey: string, initialValue) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabIndexFromUrl = +searchParams.get(tabKey);
  const [currentTab, setCurrentTab] = useState<number>(tabIndexFromUrl || initialValue);

  useEffect(() => {
    if (tabIndexFromUrl !== currentTab) {
      setSearchParams(
        (prev) => {
          prev.set(tabKey, currentTab.toString());
          return prev;
        },
        { replace: true },
      );
    }
  }, [currentTab]);

  return [currentTab, setCurrentTab] as const;
};

export default useTabParams;
